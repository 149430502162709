<template>
  <v-container>
    <v-snackbar v-model="tempSnackbar" timeout="3000" color="error">
      {{ errorText }}
    </v-snackbar>
    <v-row>
      <v-col cols="2"> {{ assetsIT[entity.name] }} </v-col>
      <v-col cols="4">
        Ultima sincronizzazione valida: {{ dateFormat(entity.lastsync) }}
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="canSync && !entity.running"
          color="primary"
          @click="sync(entity.name)"
          >SINCRONIZZA</v-btn
        >
        <p v-else-if="entity.running">In corso...</p>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row v-if="entity.lastsyncflag === 'failed'">
      <v-col cols="4">
        <strong style="color: red;">Ultima sincronizzazione fallita: {{ entity.laststatus }}</strong>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-progress-linear
          v-if="entity.running"
          v-model="entity.percent"
          height="25"
          :value="entity.percent"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <br /><br />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import { mapState } from "vuex";


export default {
  name: "SyncRow",
  data: () => ({
    tempSnackbar: false,
    errorText: "",
    buttonDisabled: false,
    assetsIT: {
        assets: 'Beni',
        rooms: 'Stanze',
        users: 'Persone',
        groups: 'Gruppi',
    }
  }),
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  created: () => {},
  computed: {
    ...mapState(["userInitials", "userRole"]),
    canSync() {
      return (
        // TODO: is operator to be included?
        this.userRole === "admin"
      );
    },
  },
  methods: {
    dateFormat(dateString) {
      return moment(dateString)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm");
    },
    sync(name) {
      this.buttonDisabled = true;
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API + "/sync/" + name,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.$emit('sync-clicked');
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err.response.statusText;
          }
          this.tempSnackbar = true;
        });
    },
  },
};
</script>
<style scoped>
.theme--light.v-progress-linear {
  color: white;
}
</style>