<template>
  <v-container>
    <v-snackbar v-model="permSnackbar" color="error">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="10">
        <v-card elevation="10" min-height="600">
          <v-card-title>Sincronizzazione dati</v-card-title>
          <v-card-text v-if="loading">
            <v-row>
              <v-spacer></v-spacer>
              <v-col>
                <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <sync-row
              v-for="entity of syncStatus.entities"
              :key="entity.name"
              :entity="entity"
              @sync-clicked="syncClicked"
            ></sync-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>
<script>
import SyncRow from "../components/SyncRow.vue";
export default {
  name: "Sync",
  components: { SyncRow },
  data: () => ({
    timeInterval: null,
    syncStatus: [],
    loading: true,
    permSnackbar: false,
    errorText: "",
    someSyncRunning: false,
    wasRunning: false,
  }),
  created: function () {
    //this.timeInterval = setInterval(this.getSyncStatus, 2000);
    this.getSyncStatus();
  },
  beforeDestroy: function () {
    clearInterval(this.timeInterval);
  },
  methods: {
    syncClicked() {
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(this.getSyncStatus, 800);
    },
    getSyncStatus() {
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/sync", {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.syncStatus = response.data;
          // console.log(response.data.entities);
          this.wasRunning = this.someSyncRunning;
          this.someSyncRunning = false;
          for (let entity of response.data.entities) {
            if (entity.running) {
              this.someSyncRunning = true;
            }
          }
          if (this.someSyncRunning) {
            if (!this.wasRunning) {
              clearInterval(this.timeInterval);
              this.timeInterval = setInterval(this.getSyncStatus, 800);
            }
          } else {
            if (this.wasRunning) {
              clearInterval(this.timeInterval);
              this.timeInterval = setInterval(this.getSyncStatus, 8000);
            } else {
              clearInterval(this.timeInterval);
              this.timeInterval = setInterval(this.getSyncStatus, 8000);
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.response) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.permSnackbar = true;

          console.log(err);
        });
    },
  },
};
</script>